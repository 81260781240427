import { TokenInfo } from "@across-protocol/app-sdk";

import { useAcross } from "../across";
import { AcrossClient } from "@across-protocol/app-sdk";
import { useAccount, useChainId, useChains, useConfig, useSwitchChain } from "wagmi";
import { MAINNET_SUPPORTED_CHAINS } from "../across";
import React, { useState } from "react";
import { getWalletClient } from "wagmi/actions";
import { ContractCallContext, ContractCallResults } from "../../web3/multicall/models";
import { ERC20, MULTICALL } from "../../web3/ABI";
import { Multicall } from "../../web3/multicall/multicall";
import BigNumber from "bignumber.js";
import { Asset } from "../../model/Asset";

export function useTokensBalance(originChainId: number | undefined, tokens: TokenInfo[] | undefined) {
    const config = useConfig();
    const chains = useChains();
    const { address } = useAccount()
    const { switchChainAsync } = useSwitchChain();
    const chainId = useChainId();

    const [tokenData, setTokenData] = useState({});

    const chainToCheck = MAINNET_SUPPORTED_CHAINS.find(_chain => _chain.id == originChainId)

    React.useEffect(() => {

        const getBalances = async () => {
            const eth_address = '0x0000000000000000000000000000000000000000'
            console.log(tokens)
            if (chainToCheck) {
                try {
                    var contractCallContext: ContractCallContext[] = [];

                    for (let item in tokens) {
                        contractCallContext.push({
                            reference: JSON.stringify(tokens[item]),
                            contractAddress: tokens[item].address,
                            abi: ERC20,
                            calls: [
                                { reference: item, methodName: "balanceOf", methodParameters: [address] },
                            ],
                        });
                    }

                    contractCallContext.push({
                        reference: 'eth_bal',
                        contractAddress: chainToCheck!.contracts!.multicall3?.address,
                        abi: MULTICALL,
                        calls: [
                            { reference: 'eth_bal', methodName: "getEthBalance", methodParameters: [address] },
                        ],
                    });
                    
                    const multicall = new Multicall({ nodeUrl: chainToCheck!.rpcUrls.default.http[0], tryAggregate: true, multicallCustomContractAddress: chainToCheck!.contracts!.multicall3?.address, network: { chainId: chainToCheck!.id, name: chainToCheck!.name } });

                    var results: ContractCallResults = await multicall.call(contractCallContext);
                    var _tokens = {}
                    for (let i in results.results) {
                        if (results.results[i].callsReturnContext[0].success == false) continue;

                        if('eth_bal' == results.results[i].originalContractCallContext.reference){
                            _tokens[eth_address].balance = new BigNumber(results.results[i].callsReturnContext[0].returnValues[0]).div(Math.pow(10, token.decimals)).toFixed();
                        } else {
                            var token = JSON.parse(results.results[i].originalContractCallContext.reference)
                            if(token.symbol == 'ETH'){
                                token.logo = token.logoUrl
                                token.active = true
                                token.balance = new BigNumber(results.results[i].callsReturnContext[0].returnValues[0]).div(Math.pow(10, token.decimals)).toFixed();
                                _tokens[eth_address] = token
                                continue
                            }
                      
                            token.logo = token.logoUrl
                            token.active = true
                            token.balance = new BigNumber(results.results[i].callsReturnContext[0].returnValues[0]).div(Math.pow(10, token.decimals)).toFixed();
                            _tokens[token.address] = token
                        }

                    }

                    setTokenData(_tokens)
                    return
                } catch (e) {
                    console.log("error finding balances! ! ");
                    console.log(e);
                }
            }
        }

        getBalances()

    }, [chainToCheck, tokens])

    return tokenData
}
