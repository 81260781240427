import styled from "styled-components";
import {Icon, Text} from "../ui/components";

interface SymbolWithLogoProps {
  logo: string;
  symbol: string;
  name?: string;
}

export const SymbolWithLogo = ({logo, symbol, name}: SymbolWithLogoProps) => {
  if (logo && logo != "") {
    return (
      <Row>
        <Icon button size="s" imageFallback={logo} />
        <Column>
          <Text h6>{symbol}</Text>
          <Text bodyRegular color="gray400" style={{textOverflow: 'ellipsis', textWrap: 'nowrap'}}>
            {name}
          </Text>
        </Column>
      </Row>
    );
  } else {
    return <Row />;
  }
};

const Row = styled.div`
  display: flex;
  align-items: center;
  max-width: 40%;
`;

const Column = styled.div`
  flex-direction: column;
  display: flex;
  text-align: left;
  margin-left: 10px;
`;
