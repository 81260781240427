import {Fragment, memo, useMemo} from "react";

import {Asset} from "../../../model/Asset";
import {Divider, Flex, IconAsset, Text} from "../../../ui/components";
import {useWalletHook} from "../../../web3/walletHook";

import {ExchangeSmartRoutePath} from "./ExchangeSmartRoutePath";

import * as SC from "./ExchangeSmartRoute.styles";

export const AssetChip = ({
  asset,
  micro,
  microHover,
  noArrow,
}: {
  asset: Asset;
  micro?: boolean;
  microHover?: boolean;
  noArrow?: boolean;
}) => {
  return (
    <SC.ChipWrapper>
      {!noArrow && (
        <SC.ArrowWrapper>
          <svg height="6" width="4" viewBox="0 0 5 5.774" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M 5 2.887 L 0 0 L 0 5.774 L 5 2.887 Z" fill="currentColor" />
          </svg>
        </SC.ArrowWrapper>
      )}
      {micro ? (
        <IconAsset asset={asset} contrast size="s" />
      ) : (
        <SC.Chip microHover={microHover}>
          <IconAsset asset={asset} button withBorder size="xs" />
          <span>{asset.symbol}</span>
        </SC.Chip>
      )}
    </SC.ChipWrapper>
  );
};

interface ExchangeSmartRoute {
  routes: Record<string | number, any>;
  routeText: any;
}

export const ExchangeSmartRoute = memo(function ExchangeSmartRouteC(props: ExchangeSmartRoute) {
  const {routes, routeText} = props;
  const {getTokenFromContract} = useWalletHook<true>();

  const list = useMemo(() => {
    const toAsset = (address: string, v3?: boolean) => ({
      ...getTokenFromContract(address),
      tag: false as const,
      v3: !!v3,
    });
    return Object.entries(routes)
      .map(([p, _]) => ({
        ...(_ as {}),
        percentage: +String(p).replace(/_\d+$/, ""),
        path: (_.route as any[])
          .map((r, i) => {
            const path = [
              {type: _.v3 ? "V3" : "V2", value: "", tag: true as const},
              toAsset(_.v3 ? r.to.contractAddress : r.to, _.v3),
            ];
            if (i === 0) {
              path.unshift({type: "percentage", value: +String(p).replace(/_\d+$/, "") + "%", tag: true as const});
              path.unshift(toAsset(_.v3 ? r.from.contractAddress : r.from));
            }
            return path;
          })
          .flat(),
      }))
      .map((_) => ({
        ..._,
        steps: _.path.slice(1, -1),
      }))
      .sort((a, b) => b.percentage - a.percentage);
  }, [routes]);

  if (!list.length) {
    return (
      <Flex justify="space-between">
        <Text bodyRegular>Route</Text>
        <Text bodyMedium color="gray400">
          —
        </Text>
      </Flex>
    );
  }

  if (routeText) {
    return (
      <Flex justify="space-between" padding="0 0 3 0">
        <Text bodyRegular>Route</Text>
        <Text bodyMedium color="orchid">
          {routeText}
        </Text>
      </Flex>
    );
  }

  return (
    <>
      <SC.Border>
        <Flex justify="space-between">
          <Text bodyRegular>Route</Text>
          <Text bodyMedium color="orchid">
            Multi Routing
          </Text>
        </Flex>
        <Divider size={6} />
        <div>
          <SC.WrapperTab>Multi Routing</SC.WrapperTab>
          <SC.Wrapper>
            <AssetChip micro noArrow asset={list[0].path[0] as Asset} />
            <Flex column grow padding="0 8" gap={1} style={{position: "relative"}}>
              <ExchangeSmartRoutePath gap={1} paths={list.length} speeds={list.map((_) => _.percentage / 100)} />
              {list.map(({steps}, i) => (
                <SC.Row key={i}>
                  {steps.map((assetOrTag, i, {length}) => (
                    <Fragment key={i}>
                      {assetOrTag.tag ? (
                        <>
                          <SC.Tag initial={assetOrTag.type === "percentage"}>
                            {assetOrTag.type === "percentage" ? assetOrTag.value : assetOrTag.type}
                          </SC.Tag>
                          {i + 1 === length && <span />}
                        </>
                      ) : (
                        <AssetChip microHover key={assetOrTag.symbol} asset={assetOrTag} />
                      )}
                    </Fragment>
                  ))}
                </SC.Row>
              ))}
            </Flex>
            <AssetChip micro asset={list[0].path[list[0].path.length - 1] as Asset} />
          </SC.Wrapper>
        </div>
      </SC.Border>
    </>
  );
});
