import React from "react";
import { AcrossClient } from "@across-protocol/app-sdk";

import {
    arbitrum,
    base,
    mainnet,
    optimism,
    polygon,
    linea,
    lisk,
    scroll,
    redstone,
    zora,
    zkSync
  } from "wagmi/chains";
  
  export const MAINNET_SUPPORTED_CHAINS = [
    /*
    arbitrum,
    base,
    optimism,
    polygon,
    linea,
    lisk,
    scroll,
    redstone,
    zora,
    */
    mainnet,
    zkSync
  ] as const;

const sdk = AcrossClient.create({
  chains: [...MAINNET_SUPPORTED_CHAINS],
  integratorId: "0x0020",
  useTestnet: false,
  logLevel: "DEBUG",
});

// assuming we want to update the sdk (??), we should pass it around via context.
const AcrossContext = React.createContext<AcrossClient>(sdk);

export function AcrossProvider({ children }: { children: React.ReactNode }) {
  return (
    <AcrossContext.Provider value={sdk}>{children}</AcrossContext.Provider>
  );
}

export function useAcross() {
  return React.useContext(AcrossContext);
}