const TOKENS_ZKSYNC_ERA = {
    eth: "0x0000000000000000000000000000000000000000",
    MUTE: "0x0e97C7a0F8B2C9885C8ac9fC6136e829CbC21d42",
    DMUTE: "0x27fb794E553DaBC2e99F6fC03F0a4B6B9C2f0E76",
    DMUTE_v3: "0x4336e06Be4F62bD757c4248c48D4C0b32615A2Df",

    //KOI
    KOI_MAINNET: "0x9d14bce1daddf408d77295bb1be9b343814f44de",
    KOI: "0xa995ad25ce5eb76972ab356168f5e1d9257e4d05",
    VEKOI: "0x98dB4e3Df6502369dAD7AC99f3aEE5D064721C4C",
  
    //tokens
    USDC: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
    USDT: "0x493257fD37EDB34451f62EDf8D2a0C418852bA4C",
    WBTC: "0xBBeB516fb02a01611cBBE0453Fe3c580D7281011",
    LUSD: "0x503234f203fc7eb888eec8513210612a43cf6115",
    DAI: "0x4B9eb6c0b6ea15176BBF62841C6B2A8a398cb656",
    HOLD: "0xed4040fd47629e7c8fbb7da76bb50b3e7695f0f2",
    ZORRO: "0x244c238325fc1bdf6eded726ee1b47d55895d944",
    USDC_NATIVE: "0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4",
    ZKSYNC: "0x5a7d6b2f92c77fad6ccabd7ee0624e64907eaf3e",

    USDM: "0x7715c206a14ac93cb1a6c0316a6e5f8ad7c9dc31",
    WUSDM: "0xa900cbe7739c96d2b153a273953620a701d5442b",


    stables: [
        "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
        "0x493257fD37EDB34451f62EDf8D2a0C418852bA4C",
        "0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4"
    ],

    pinned: [
        "0x0000000000000000000000000000000000000000",
        "0x5a7d6b2f92c77fad6ccabd7ee0624e64907eaf3e",
        "0xBBeB516fb02a01611cBBE0453Fe3c580D7281011",
        "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
        "0x493257fD37EDB34451f62EDf8D2a0C418852bA4C",
    ],

    ve_rewards: [
        "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
        "0x5a7d6b2f92c77fad6ccabd7ee0624e64907eaf3e",
        "0xa995ad25ce5eb76972ab356168f5e1d9257e4d05",
        "0x244c238325fc1bdf6eded726ee1b47d55895d944",
    ]
};

const TOKENS_SOPHON_SEPOLIA = {
    USDC: "0x155F5f62Ae114a46aF924FAe3a9b994849076403",
    USDC_NATIVE: "0x01",

    USDM: "0x01",
    WUSDM: "0x01",

    WSOPH: "0x9B7EA63EF5062661570afe548e3AD691D5652527",

    stables: [
        "0x155F5f62Ae114a46aF924FAe3a9b994849076403"
    ],

    pinned: [
        "0x0000000000000000000000000000000000000000",
        "0x9B7EA63EF5062661570afe548e3AD691D5652527",
        "0x155F5f62Ae114a46aF924FAe3a9b994849076403",
    ],

    ve_rewards: [
        
    ]
};
  

export {TOKENS_ZKSYNC_ERA, TOKENS_SOPHON_SEPOLIA}