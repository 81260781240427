import { useChains } from "wagmi";
import { useSupportedAcrossChains } from "./useSupportedAcrossChains";
import { reduceAcrossChains } from "./utils";
import { MAINNET_SUPPORTED_CHAINS } from "../across";

export function useAcrossChains() {
  //const chains = useChains();
  const { supportedChains } = useSupportedAcrossChains({});
  const chains = MAINNET_SUPPORTED_CHAINS

  /*
  if(supportedChains?.length){
    return supportedChains
  }
  */
  
  // use only token data for chains we support
  if (chains?.length && supportedChains?.length) {
    return reduceAcrossChains(supportedChains, [...chains]);
  }
}
